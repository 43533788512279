import React from "react"
import classNames from "classnames"

import Address from "../../../PersonalDetails/Address"
import FormInput from "../FormInput"
import FormCheckbox from "../FormCheckbox"
import FormRadio from "../FormRadio"
import FormSelect from "../FormSelect"
import FormDate from "../FormDate"
import FormLikertRadio from "../FormLikertRadio"

import styles from "../../utils/elements.module.scss"
import FormDateV2 from "../FormDateV2"

export const generateFormField = (config) => {
  let { formField, formFields, values, setFieldValue, onChange } = config
  switch (formField?.type) {
    case "text":
    case "number":
    case "password":
      return (
        <FormInput
          type={formField?.type === "text" ? "" : formField?.type}
          isFollowUpQuestion
          {...formField}
        />
      )
    case "date":
      return (
        <FormDate
          values={values[formField?.name]}
          onChange={onChange}
          {...formField}
        />
      )
    case "datev2":
      return (
        <FormDateV2
          values={values[formField?.name]}
          onChange={onChange}
          {...formField}
        />
      )
    case "checkbox":
      return (
        <FormCheckbox
          values={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "radio":
      return (
        <FormRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "select":
      return <FormSelect values={values} {...formField} />
    case "address":
      return (
        <Address
          setFieldValue={setFieldValue}
          values={values}
          {...formField}
          isNationwide
          isRequired
        />
      )
    case "message":
      return (
        <section className={formField.className || ""}>
          {formField.content}
        </section>
      )
    case "scale":
      return (
        <FormLikertRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "section":
      return (
        <div className={classNames(styles["section"], "mb-1")}>
          <div>
            <h4 className="has-text-primary mt-0 mb-0">{formField?.content}</h4>
            {formField?.helper ? (
              <p className="mb-1 has-text-grey is-size-6">
                {formField?.helper}
              </p>
            ) : null}
          </div>
        </div>
      )
    default:
      return null
  }
}

export const getFollowUpQuestionData = (config) => {
  let { followUpQuestion, formFields } = config
  let followUpData = formFields.filter((formField) => {
    return followUpQuestion === formField.name
  })

  if (followUpData.length > 0) return followUpData[0]
  else return {}
}
