import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"
import moment from "moment"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RadioContainerButton from "./RadioContainerButton"

import styles from "../utils/epharmacy.module.scss"

import {
  faLocationDot,
  faTruck,
  faMapPin,
  faHourglassHalf,
  faPesoSign,
} from "@fortawesome/free-solid-svg-icons"

const icons = {
  faLocationDot,
  faTruck,
  faMapPin,
  faHourglassHalf,
  faPesoSign,
}

const CourierOption = ({
  setFieldValue,
  values,
  courierOption,
  shippingOptionsImages,
  cardInfo,
}) => {
  const handleCourierSelect = () => {
    setFieldValue("courierOption", courierOption.value)
    setFieldValue("paymentOption", { label: "", value: "" })
    setFieldValue("preferredDeliveryDate", {
      date: {
        label: "",
        value: "",
      },
      month: {
        label: "",
        value: "",
      },
      year: {
        label: "",
        value: "",
      },
    })
    setFieldValue("changeFor", "")
  }

  return (
    <div
      className={classNames(
        styles[
          `shippingDetails__shippingOption${
            values.courierOption === courierOption.value ? "Active" : ""
          }`
        ]
      )}
      onClick={handleCourierSelect}
    >
      <div
        className={classNames(styles["shippingDetails__shippingOptionInfo"])}
      >
        <div
          className={classNames(styles["shippingDetails__shippingOptionImage"])}
        >
          <RadioContainerButton
            isSelected={values?.courierOption === courierOption?.value}
          />
          <Img
            fixed={
              shippingOptionsImages[courierOption.imgName]?.childImageSharp
                .fixed
            }
          />
        </div>
        {Object.keys(courierOption?.[cardInfo]?.cardInfo).map((capability) => (
          <div
            key={capability}
            className={classNames(
              styles["shippingDetails__shippingOptionCapability"]
            )}
          >
            <div
              className={classNames(
                styles["shippingDetails__shippingOptionCapabilityHeader"]
              )}
            >
              <div
                className={classNames(
                  styles[
                    `shippingDetails__shippingOptionCapabilityIcon${
                      values.courierOption === courierOption.value
                        ? "Active"
                        : "Inactive"
                    }`
                  ]
                )}
              >
                <FontAwesomeIcon
                  icon={
                    icons[courierOption?.[cardInfo]?.cardInfo[capability]?.icon]
                  }
                />
              </div>
              <strong>
                {courierOption?.[cardInfo]?.cardInfo[capability]?.label}
              </strong>
            </div>
            <div
              className={classNames(
                styles["shippingDetails__shippingOptionCapabilityContent"]
              )}
            >
              <ul>
                {courierOption?.[cardInfo]?.cardInfo[capability]?.info &&
                  Object.keys(
                    courierOption?.[cardInfo]?.cardInfo[capability]?.info
                  ).map((key) => (
                    <li>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            courierOption?.[cardInfo]?.cardInfo[capability]
                              ?.info[key],
                        }}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CourierOption
